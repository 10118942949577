import React, { useState } from 'react'

export default function TextForm(props) {
    const handleUpClick = ()=>{
        let newText = text.toUpperCase()
        setText(newText)
        props.showAlert('Converted to uppercase','success')

    }
    const handleLoClick = ()=>{
        let newText = text.toLowerCase()
        setText(newText)
        props.showAlert("Converted to lowercase", "success");
    }
    const handleOnChange = (event)=>{
        setText(event.target.value)
    }
    const handleClear = () => {
      setText('');
      props.showAlert("Text Cleared", "success");
    };
     const handleCopy = () => {
       navigator.clipboard.writeText(text);
       props.showAlert("Copied to clipboard", "success");

     };

     const handleExtraSpaces = () =>{
        let newText = text.split(/[ ]+/);
        setText(newText.join(' '));
        props.showAlert("Removed extra spaces", "success");
     }
    const [text, setText] = useState('');
    return (
      <>
        <div
          className="container"
          style={{
            color: props.mode === "dark" ? "white" : "#01386e",
          }}
        >
          <h1 className="mb-4">{props.heading}</h1>
          <div className="mb-3">
            <textarea
              className="form-control"
              value={text}
              style={{
                backgroundColor: props.mode === "dark" ? "#01386e" : "white",
                color: props.mode === "dark" ? "white" : "#01386e",
              }}
              onChange={handleOnChange}
              id="myBox"
              rows="8"
            ></textarea>
          </div>
          <button
            disabled={text.length === 0}
            className="btn btn-primary mx-2 my-2"
            onClick={handleUpClick}
          >
            Convert to Uppercase
          </button>
          <button
            disabled={text.length === 0}
            className="btn btn-primary mx-2 my-2"
            onClick={handleLoClick}
          >
            Convert to Lowercase
          </button>
          <button
            disabled={text.length === 0}
            className="btn btn-primary mx-2 my-2"
            onClick={handleClear}
          >
            Clear
          </button>
          <button
            disabled={text.length === 0}
            className="btn btn-primary mx-2 my-2"
            onClick={handleCopy}
          >
            Copy
          </button>
          <button
            disabled={text.length === 0}
            className="btn btn-primary mx-2 my-2"
            onClick={handleExtraSpaces}
          >
            Remove Extra Spaces
          </button>
        </div>
        <div
          className="container my-3"
          style={{
            color: props.mode === "dark" ? "white" : "#01386e",
          }}
        >
          <h2>Your text summary</h2>
          <p>
            {
              text.split(/\s+/).filter((element) => {
                return element.length !== 0;
              }).length
            }{" "}
            words, {text.length} characters
          </p>
          <p>
            {0.008 *
              text.split(" ").filter((element) => {
                return element.length !== 0;
              }).length}
             minutes to read
          </p>
          <h2>Preview</h2>
          <p>
            {text.length > 0
              ? text
              : "Nothing to preview"}
          </p>
        </div>
      </>
    );
}
